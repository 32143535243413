import { IEnvironment } from './environment.interface';

export const environment: IEnvironment = {
  production: false,
  STORAGE_URL: '',
  STORAGE_URL_IDENTITY: 'https://storage.identity.dev.ilwith.io',
  BASE_URL: 'https://dev.inlivewith.bo.139.99.27.199.ilwith.io',
  IDENTITY_URL: 'https://dev.identity.ilwith.io',
  API_URL: 'https://dev.bo.ilw.139.99.27.198.ilwith.io',
  WEB_PORTAL_URL: 'https://dev.identity.ui.ilwith.io',
  INLIVE_GROUP_URL: 'https://inlive-group.com/',
  EMAIL_API_URL: 'https://email.ilwith.io',
  GOOGLE_API_KEY: 'AIzaSyDtLxBSzPRNpy-u_bALJbNCAcpAlKvky5Y',
  STANDARD_URL: '',
  HASH_TAG_URL: '',
  FCM_TEST_URL: '',
  FCM_APP_ID: '',
  SIGNALR_URL: 'https://signalr.ilwith.io',
  TEMP_TOKEN: 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VybmFtZSI6Imtob2ljM2IxOTk5QGdtYWlsLmNvbSIsImVtYWlsIjoia2hvaWMzYjE5OTlAZ21haWwuY29tIiwic3ViIjoiYjU4NjYzMjctNjAxZi00NDcyLWE4YjItZTRiYjYwNzc4ZjBkIiwianRpIjoiYjAyOTFlMjgtNTU0Yi00Nzg5LWI0OWUtNDRiZmFhMGM5NjVjIiwiaWF0IjoxNjk1MTAzNjU0LCJodHRwOi8vc2NoZW1hcy5taWNyb3NvZnQuY29tL3dzLzIwMDgvMDYvaWRlbnRpdHkvY2xhaW1zL3JvbGUiOiJhZG1pbmlzdHJhdG9ycyIsIm5iZiI6MTY5NTEwMzY1NCwiZXhwIjoxNjk1MTEwODU0LCJpc3MiOiJ3ZWJBcGkiLCJhdWQiOiJodHRwOi8vbG9jYWxob3N0OjUwMzEvIn0.aYNsuTNW5seI_0e0CRxxUST6irzuI55qzXvs1dICJLw',
  VERSION: 'dev - 0.9.2',
  ADDRESS_WEB_URL: 'https://www.address.ilwith.io',
  INLIVEWITH_API: 'https://newpost.ilw.api.139.99.27.198.ilwith.io',
  UPLOAD_URL: "https://stag.upload.ilwith.io",
  SERVICE_ID: 'inlivewith-dev'
};
